<template>
    <GuestPageFrame>
        <h3 class="mb-8">Bejelentkezés</h3>
        <ValidationObserver ref="form" v-slot="{ handleSubmit }" slim>
            <form novalidate class="w-full" @submit.prevent="handleSubmit(onSubmit)">
                <div class="grid grid-cols-6 gap-5">
                    <div class="col-span-6">
                        <ValidationProvider v-slot="{ errors }" vid="email" tag="div">
                            <InputText
                                v-model="email"
                                name="email"
                                placeholder="E-mail"
                                :class="{ 'p-invalid': errors.length }"
                                type="email"
                            />
                            <InputError :errors="errors" class="ml-5" />
                        </ValidationProvider>
                    </div>

                    <div class="col-span-6">
                        <ValidationProvider v-slot="{ errors }" vid="password" tag="div">
                            <Password
                                id="password"
                                v-model="password"
                                name="password"
                                :feedback="false"
                                toggle-mask
                                :class="{ 'p-invalid': errors.length }"
                                placeholder="Jelszó"
                            />
                            <InputError :errors="errors" class="ml-5" />
                        </ValidationProvider>
                    </div>

                    <div class="col-span-6">
                        <div class="p-field">
                            <Checkbox id="remember_me" v-model="rememberMe" :binary="true" />
                            <label for="remember_me">Maradjak bejelentkezve</label>
                        </div>
                    </div>
                    <div class="col-span-6 mt-6">
                        <Button type="submit" label="Bejelentkezés" class="w-full flex justify-center" />
                    </div>
                </div>
            </form>
        </ValidationObserver>
    </GuestPageFrame>
</template>

<script>
import GuestPageFrame from '../components/Layout/GuestPageFrame';

export default {
    name: 'Login',
    components: {
        GuestPageFrame,
    },
    layout: 'guest',
    data() {
        return {
            email: '',
            password: '',
        };
    },
    computed: {
        rememberMe: {
            get() {
                return this.$store.state.auth.rememberMe;
            },
            set(val) {
                this.$store.commit('auth/setRememberMe', val);
            },
        },
    },
    methods: {
        async onSubmit() {
            try {
                await this.$axios
                    .$post('/auth/login', {
                        email: this.email,
                        password: this.password,
                    })
                    .then((res) => {
                        if (res.success === 1) {
                            this.$auth.logIn(res.data.user, res.data.accessToken);
                            this.$router.replace('/');
                        } else {
                            console.log(res.errors);
                            this.$refs.form.setErrors(res.errors);
                        }
                    });
            } catch (err) {
                console.log(err);
            }
        },
    },
};
</script>

<style scoped></style>
