<template>
    <div
        class="bg-cover bg-center relative"
        :style="`background-image: url(${require('~/assets/images/illustrations/login_background.png')})`"
    >
        <div class="absolute w-full h-full top-0 left-0 bg-black opacity-50 z-0"></div>
        <img
            :src="require('~/assets/images/logos/text_logo.png')"
            alt="Reggeli logo"
            class="absolute z-1 top-5 left-1/2 -translate-x-1/2 lg:translate-x-0 lg:left-5 h-24 lg:h-auto"
        />
        <div class="relative z-1 min-h-screen flex items-center justify-center">
            <div class="container">
                <div class="grid grid-cols-12 gap-5">
                    <div class="col-span-12 lg:col-span-4 lg:col-start-5 pb-12">
                        <div class="bg-white rounded-lg p-5">
                            <slot />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GuestPageFrame',
};
</script>

<style scoped></style>
